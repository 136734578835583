import React from "react";
import Icon1 from "../assets/images/how-it-works-1.svg";
import Icon2 from "../assets/images/how-it-works-2.svg";
import Icon3 from "../assets/images/how-it-works-3.svg";
import Icon4 from "../assets/images/how-it-works-4.svg";

const HomeHowItWorks = () => {
  return (
    <div className="how-it-works">
      <div className="container">
        <h2 className="text-center">How it works</h2>
        <h5 className="text-center">
          Plan your dream trip in just a few steps with TripDarwin.
        </h5>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-3">
                <div className="box-wrap">
                  <div className="box-bg">
                    <div>
                      <span className="number">1</span>
                      <div className="icon">
                        <img
                          src={Icon1}
                          alt="Create your dream adventure in just a few steps with TripDarwin."
                        />
                      </div>
                      <h4>
                        Compare packages across multiple destinations in one
                        place.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box-wrap">
                  <div className="box-bg">
                    <div>
                      <span className="number">2</span>
                      <div className="icon">
                        <img
                          src={Icon2}
                          alt="Filter packages by budget, duration & other preferences."
                        />
                      </div>
                      <h4>
                        Filter packages by budget, duration & other preferences.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box-wrap">
                  <div className="box-bg">
                    <div>
                      <span className="number">3</span>
                      <div className="icon">
                        <img
                          src={Icon3}
                          alt="Review itinerary and all the other details to zero in on your favorite package."
                        />
                      </div>
                      <h4>
                        Review itinerary and all the other details to zero in on
                        your favorite package.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box-wrap">
                  <div className="box-bg">
                    <div>
                      <div className="icon">
                        <span className="number">4</span>
                        <img
                          src={Icon4}
                          alt="Connect with our experts on Whats App to customize and book the package."
                        />
                      </div>
                      <h4>
                        Connect with our experts on Whats App to customize and
                        book the package.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
};

export default HomeHowItWorks;
